/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {Address} from '../model/Address';
import {Addresses} from '../model/Addresses';
import {Ads} from '../model/Ads';
import {Agencies} from '../model/Agencies';
import {BannerAds} from '../model/BannerAds';
import {CartComparisonDebug} from '../model/CartComparisonDebug';
import {CartConfig} from '../model/CartConfig';
import {CartItem} from '../model/CartItem';
import {Category} from '../model/Category';
import {Checkout} from '../model/Checkout';
import {ComparedBaskets} from '../model/ComparedBaskets';
import {CreateAddressResponse} from '../model/CreateAddressResponse';
import {ForgotPasswordResponse} from '../model/ForgotPasswordResponse';
import {Leaflets} from '../model/Leaflets';
import {OfferSearchResponse} from '../model/OfferSearchResponse';
import {Offers} from '../model/Offers';
import {Order} from '../model/Order';
import {Orders} from '../model/Orders';
import {OrdersIndex} from '../model/OrdersIndex';
import {ProductShowJson} from '../model/ProductShowJson';
import {ResetPasswordPayload} from '../model/ResetPasswordPayload';
import {ResetPasswordResponse} from '../model/ResetPasswordResponse';
import {RootCategories} from '../model/RootCategories';
import {ScopeValidateTokenBody} from '../model/ScopeValidateTokenBody';
import {SignInPayload} from '../model/SignInPayload';
import {SignInResponse} from '../model/SignInResponse';
import {SignUpPayload} from '../model/SignUpPayload';
import {SignUpResponse} from '../model/SignUpResponse';
import {Supplier} from '../model/Supplier';
import {Suppliers} from '../model/Suppliers';
import {ValidatePasswordResetToken} from '../model/ValidatePasswordResetToken';

/**
* Default service.
* @module api/DefaultApi
* @version v1
*/
export class DefaultApi {

    /**
    * Constructs a new DefaultApi. 
    * @alias module:api/DefaultApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * compare
     * @param {Object} opts Optional parameters
     * @param {String} opts.id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ComparedBaskets} and HTTP response
     */
    cartCompareWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'id': opts['id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ComparedBaskets;

      return this.apiClient.callApi(
        '/cart/v1/compare', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * compare
     * @param {Object} opts Optional parameters
     * @param {String} opts.id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ComparedBaskets}
     */
    cartCompare(opts) {
      return this.cartCompareWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * compare_debug
     * @param {Object} opts Optional parameters
     * @param {String} opts.id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CartComparisonDebug} and HTTP response
     */
    cartCompareDebugWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'id': opts['id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CartComparisonDebug;

      return this.apiClient.callApi(
        '/cart/v1/compare_debug', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * compare_debug
     * @param {Object} opts Optional parameters
     * @param {String} opts.id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CartComparisonDebug}
     */
    cartCompareDebug(opts) {
      return this.cartCompareDebugWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * cart_sync
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CartItem>} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    cartSyncWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/cart/v1/sync', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * cart_sync
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CartItem>} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    cartSync(opts) {
      return this.cartSyncWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch ads
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Ads} and HTTP response
     */
    fetchAdsWithHttpInfo() {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Ads;

      return this.apiClient.callApi(
        '/api/v2/ads', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch ads
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Ads}
     */
    fetchAds() {
      return this.fetchAdsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * index
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Agencies} and HTTP response
     */
    fetchAgenciesWithHttpInfo() {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Agencies;

      return this.apiClient.callApi(
        '/api/v2/agencies', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * index
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Agencies}
     */
    fetchAgencies() {
      return this.fetchAgenciesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch banner ads
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BannerAds} and HTTP response
     */
    fetchBannerAdsWithHttpInfo() {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = BannerAds;

      return this.apiClient.callApi(
        '/api/v2/banners', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch banner ads
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BannerAds}
     */
    fetchBannerAds() {
      return this.fetchBannerAdsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch cart config
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CartConfig} and HTTP response
     */
    fetchCartConfigWithHttpInfo() {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CartConfig;

      return this.apiClient.callApi(
        '/cart/v1/checkout_config', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch cart config
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CartConfig}
     */
    fetchCartConfig() {
      return this.fetchCartConfigWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_category
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    fetchCategoryWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchCategory");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/catalog/v1/categories/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_category
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    fetchCategory(id, opts) {
      return this.fetchCategoryWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch category banners
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Offers} and HTTP response
     */
    fetchCategoryBannersWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchCategoryBanners");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Offers;

      return this.apiClient.callApi(
        '/catalog/v1/categories/{id}/banners', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch category banners
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Offers}
     */
    fetchCategoryBanners(id, opts) {
      return this.fetchCategoryBannersWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_leaflets
     * @param {Object} opts Optional parameters
     * @param {String} opts.lon 
     * @param {String} opts.lat 
     * @param {String} opts.radius 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Leaflets} and HTTP response
     */
    fetchLeafletsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'lon': opts['lon'],'lat': opts['lat'],'radius': opts['radius']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Leaflets;

      return this.apiClient.callApi(
        '/api/v2/leaflets', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_leaflets
     * @param {Object} opts Optional parameters
     * @param {String} opts.lon 
     * @param {String} opts.lat 
     * @param {String} opts.radius 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Leaflets}
     */
    fetchLeaflets(opts) {
      return this.fetchLeafletsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * orders
     * @param {String} user_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Orders} and HTTP response
     */
    fetchOpenOrdersWithHttpInfo(user_id) {
      
      let postBody = null;
      // verify the required parameter 'user_id' is set
      if (user_id === undefined || user_id === null) {
        throw new Error("Missing the required parameter 'user_id' when calling fetchOpenOrders");
      }

      let pathParams = {
        'user_id': user_id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Orders;

      return this.apiClient.callApi(
        '/api/v2/users/{user_id}/orders/incomplete', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * orders
     * @param {<&vendorExtensions.x-jsdoc-type>} user_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Orders}
     */
    fetchOpenOrders(user_id) {
      return this.fetchOpenOrdersWithHttpInfo(user_id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_order
     * @param {String} order_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    fetchOrderWithHttpInfo(order_id) {
      
      let postBody = null;
      // verify the required parameter 'order_id' is set
      if (order_id === undefined || order_id === null) {
        throw new Error("Missing the required parameter 'order_id' when calling fetchOrder");
      }

      let pathParams = {
        'order_id': order_id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/api/v2/orders/{order_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_order
     * @param {<&vendorExtensions.x-jsdoc-type>} order_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    fetchOrder(order_id) {
      return this.fetchOrderWithHttpInfo(order_id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * orders
     * @param {String} user_id uuid
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page page number
     * @param {Number} opts.per_page number of items per page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrdersIndex} and HTTP response
     */
    fetchOrdersWithHttpInfo(user_id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'user_id' is set
      if (user_id === undefined || user_id === null) {
        throw new Error("Missing the required parameter 'user_id' when calling fetchOrders");
      }

      let pathParams = {
        'user_id': user_id
      };
      let queryParams = {
        'page': opts['page'],'per_page': opts['per_page']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OrdersIndex;

      return this.apiClient.callApi(
        '/api/v2/users/{user_id}/orders', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * orders
     * @param {<&vendorExtensions.x-jsdoc-type>} user_id uuid
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page page number
     * @param {Number} opts.per_page number of items per page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrdersIndex}
     */
    fetchOrders(user_id, opts) {
      return this.fetchOrdersWithHttpInfo(user_id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_product
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductShowJson} and HTTP response
     */
    fetchProductWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchProduct");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProductShowJson;

      return this.apiClient.callApi(
        '/catalog/v1/products/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_product
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductShowJson}
     */
    fetchProduct(id, opts) {
      return this.fetchProductWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * root_categories
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RootCategories} and HTTP response
     */
    fetchRootCategoriesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = RootCategories;

      return this.apiClient.callApi(
        '/catalog/v1/categories', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * root_categories
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RootCategories}
     */
    fetchRootCategories(opts) {
      return this.fetchRootCategoriesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * sellable_offers
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Offers} and HTTP response
     */
    fetchSellableOffersWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchSellableOffers");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Offers;

      return this.apiClient.callApi(
        '/catalog/v1/sellables/{id}/offers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * sellable_offers
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Offers}
     */
    fetchSellableOffers(id, opts) {
      return this.fetchSellableOffersWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_supplier
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Supplier} and HTTP response
     */
    fetchSupplierWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchSupplier");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Supplier;

      return this.apiClient.callApi(
        '/api/v2/suppliers/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_supplier
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Supplier}
     */
    fetchSupplier(id) {
      return this.fetchSupplierWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_suppliers
     * @param {Object} opts Optional parameters
     * @param {String} opts.lat 
     * @param {String} opts.lon 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Suppliers} and HTTP response
     */
    fetchSuppliersWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'lat': opts['lat'],'lon': opts['lon']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Suppliers;

      return this.apiClient.callApi(
        '/api/v2/suppliers/distance_from', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_suppliers
     * @param {Object} opts Optional parameters
     * @param {String} opts.lat 
     * @param {String} opts.lon 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Suppliers}
     */
    fetchSuppliers(opts) {
      return this.fetchSuppliersWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * addresses
     * @param {String} user_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Addresses} and HTTP response
     */
    fetchUserAddressesWithHttpInfo(user_id) {
      
      let postBody = null;
      // verify the required parameter 'user_id' is set
      if (user_id === undefined || user_id === null) {
        throw new Error("Missing the required parameter 'user_id' when calling fetchUserAddresses");
      }

      let pathParams = {
        'user_id': user_id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Addresses;

      return this.apiClient.callApi(
        '/api/v2/users/{user_id}/addresses', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * addresses
     * @param {<&vendorExtensions.x-jsdoc-type>} user_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Addresses}
     */
    fetchUserAddresses(user_id) {
      return this.fetchUserAddressesWithHttpInfo(user_id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * user forgot password
     * @param {String} scope 
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ForgotPasswordResponse} and HTTP response
     */
    forgotPasswordWithHttpInfo(scope, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'scope' is set
      if (scope === undefined || scope === null) {
        throw new Error("Missing the required parameter 'scope' when calling forgotPassword");
      }

      let pathParams = {
        'scope': scope
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ForgotPasswordResponse;

      return this.apiClient.callApi(
        '/api/v2/account/{scope}/forgot_password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * user forgot password
     * @param {<&vendorExtensions.x-jsdoc-type>} scope 
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ForgotPasswordResponse}
     */
    forgotPassword(scope, opts) {
      return this.forgotPasswordWithHttpInfo(scope, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * new address
     * @param {String} user_id uuid
     * @param {Object} opts Optional parameters
     * @param {module:model/Address} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreateAddressResponse} and HTTP response
     */
    newUserAddressWithHttpInfo(user_id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'user_id' is set
      if (user_id === undefined || user_id === null) {
        throw new Error("Missing the required parameter 'user_id' when calling newUserAddress");
      }

      let pathParams = {
        'user_id': user_id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CreateAddressResponse;

      return this.apiClient.callApi(
        '/api/v2/users/{user_id}/addresses', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * new address
     * @param {<&vendorExtensions.x-jsdoc-type>} user_id uuid
     * @param {Object} opts Optional parameters
     * @param {module:model/Address} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreateAddressResponse}
     */
    newUserAddress(user_id, opts) {
      return this.newUserAddressWithHttpInfo(user_id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * order_sync
     * @param {Object} opts Optional parameters
     * @param {module:model/Order} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    orderSyncWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/order/v1/sync', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * order_sync
     * @param {Object} opts Optional parameters
     * @param {module:model/Order} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    orderSync(opts) {
      return this.orderSyncWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * place_order
     * @param {Object} opts Optional parameters
     * @param {module:model/Checkout} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    placeOrderWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/cart/v1/order', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * place_order
     * @param {Object} opts Optional parameters
     * @param {module:model/Checkout} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    placeOrder(opts) {
      return this.placeOrderWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * reset password
     * @param {String} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/ResetPasswordPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ResetPasswordResponse} and HTTP response
     */
    resetPasswordWithHttpInfo(scope, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'scope' is set
      if (scope === undefined || scope === null) {
        throw new Error("Missing the required parameter 'scope' when calling resetPassword");
      }

      let pathParams = {
        'scope': scope
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ResetPasswordResponse;

      return this.apiClient.callApi(
        '/api/v2/account/{scope}/reset_password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * reset password
     * @param {<&vendorExtensions.x-jsdoc-type>} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/ResetPasswordPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ResetPasswordResponse}
     */
    resetPassword(scope, opts) {
      return this.resetPasswordWithHttpInfo(scope, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * offers
     * @param {Object} opts Optional parameters
     * @param {String} opts.query 
     * @param {String} opts.supplier_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OfferSearchResponse} and HTTP response
     */
    searchOffersWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'query': opts['query'],'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OfferSearchResponse;

      return this.apiClient.callApi(
        '/catalog/v1/offers/search', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * offers
     * @param {Object} opts Optional parameters
     * @param {String} opts.query 
     * @param {String} opts.supplier_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OfferSearchResponse}
     */
    searchOffers(opts) {
      return this.searchOffersWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * account sign in
     * @param {String} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/SignInPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SignInResponse} and HTTP response
     */
    signInWithHttpInfo(scope, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'scope' is set
      if (scope === undefined || scope === null) {
        throw new Error("Missing the required parameter 'scope' when calling signIn");
      }

      let pathParams = {
        'scope': scope
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = SignInResponse;

      return this.apiClient.callApi(
        '/api/v2/account/{scope}/sign_in', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * account sign in
     * @param {<&vendorExtensions.x-jsdoc-type>} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/SignInPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SignInResponse}
     */
    signIn(scope, opts) {
      return this.signInWithHttpInfo(scope, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * account sign up
     * @param {String} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/SignUpPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SignUpResponse} and HTTP response
     */
    signUpWithHttpInfo(scope, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'scope' is set
      if (scope === undefined || scope === null) {
        throw new Error("Missing the required parameter 'scope' when calling signUp");
      }

      let pathParams = {
        'scope': scope
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = SignUpResponse;

      return this.apiClient.callApi(
        '/api/v2/account/{scope}/sign_up', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * account sign up
     * @param {<&vendorExtensions.x-jsdoc-type>} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/SignUpPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SignUpResponse}
     */
    signUp(scope, opts) {
      return this.signUpWithHttpInfo(scope, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * updates a user
     * @param {String} scope 
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {module:model/SignInPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SignInResponse} and HTTP response
     */
    updateAccountWithHttpInfo(scope, id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'scope' is set
      if (scope === undefined || scope === null) {
        throw new Error("Missing the required parameter 'scope' when calling updateAccount");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateAccount");
      }

      let pathParams = {
        'scope': scope,'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = SignInResponse;

      return this.apiClient.callApi(
        '/api/v2/account/{scope}/{id}/update', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * updates a user
     * @param {<&vendorExtensions.x-jsdoc-type>} scope 
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {module:model/SignInPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SignInResponse}
     */
    updateAccount(scope, id, opts) {
      return this.updateAccountWithHttpInfo(scope, id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * validate password reset token
     * @param {String} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/ScopeValidateTokenBody} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ValidatePasswordResetToken} and HTTP response
     */
    validatePasswordResetTokenWithHttpInfo(scope, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'scope' is set
      if (scope === undefined || scope === null) {
        throw new Error("Missing the required parameter 'scope' when calling validatePasswordResetToken");
      }

      let pathParams = {
        'scope': scope
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ValidatePasswordResetToken;

      return this.apiClient.callApi(
        '/api/v2/account/{scope}/validate_token', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * validate password reset token
     * @param {<&vendorExtensions.x-jsdoc-type>} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/ScopeValidateTokenBody} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ValidatePasswordResetToken}
     */
    validatePasswordResetToken(scope, opts) {
      return this.validatePasswordResetTokenWithHttpInfo(scope, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }

}